import axios from 'axios';
import {
	PageLayout,
	FormInput,
	Button,
	getApiUrl,
	getThemeFromUrl,
	customToast,
	getLangPageFromApi,
	SelectInput,
	PostcodeLookup,
	StringHelpers,
} from 'carrier-fe';
import { useEffect, useMemo, useReducer, useState } from 'react';
import { LangMyCompany } from '../../../types/lang/my-company';

const defaultData = {
	name: '',
	address_line_1: '',
	address_line_2: '',
	address_line_3: '',
	town_city: '',
	state_county: '',
	postcode_zipcode: '',
	country_code_iso_3: '',
	fgas_refcom_standard_number: '',
	refcom_elite_number: '',
	account_number: '',
	dispatch_note_field_label: '',
	dispatch_note_required: false,
	distributor_claim_type: '',
	distributor_claim_inbox_email: '',
	gas_boiler_systems_enabled: false,
	heat_pumps_systems_enabled: false,
	solar_systems_enabled: false,
	other_systems_enabled: false,
	gas_safe_number: '',
	gas_safe_confirmation: false,
	type: '',
};

type Data = typeof defaultData;

type GetBoolKeys<T> = {
	[K in keyof T]: T[K] extends boolean ? K : never;
}[keyof T];

type GetStringKeys<T> = {
	[K in keyof T]: T[K] extends string ? K : never;
}[keyof T];

type OnlyBoolKeys<T> = Omit<T, Exclude<keyof T, GetBoolKeys<T>>>;

type OnlyStringKeys<T> = Omit<T, Exclude<keyof T, GetStringKeys<T>>>;

type Page<T> = {
	page: {
		fields: T;
		submit_button: {
			default: 'Update';
			submitting: 'Updating...';
		};
		title: 'My Company';
	};
};

function MyCompany() {
	const [isFetching, setIsFetching] = useState(false);
	const [isUpdating, setIsUpdating] = useState(false);
	const [lang, setLang] = useState<Page<LangMyCompany>>();
	const [errors, setErrors] = useState<any>(null);
	const [data, mutateData] = useReducer(
		(state: Data, action: Partial<Data>) => {
			return {
				...state,
				...action,
			};
		},
		defaultData
	);

	const theme = useMemo(() => getThemeFromUrl(), []);

	const read = async <T,>() => {
		if (isFetching) return;
		setIsFetching(true);

		const res = await axios.get<T>(`${getApiUrl()}/v1/my-company/update`);
		setIsFetching(false);

		return res;
	};

	const update = async () => {
		try {
			if (isUpdating) return;
			setIsUpdating(true);

			const res = await axios.patch<{ data: Data; message: string }>(
				`${getApiUrl()}/v1/my-company/update`,
				data
			);

			customToast({
				variant: 'Success',
				title: res.data.message,
			});

			return res;
		} catch (err: any) {
			setErrors(err.response.data.data.errors);
			customToast({
				variant: 'Danger',
				title: err.response.data.message,
			});
		} finally {
			setIsUpdating(false);
		}
	};

	const Checkbox = (name: keyof OnlyBoolKeys<Data>, label: string) => (
		<div className="d-flex gap-2 mb-4">
			<span>{lang?.page.fields[name] || label}:</span>
			<input
				type="checkbox"
				name={name}
				checked={data[name]}
				onChange={(e) => mutateData({ [name]: e.target.checked })}
			/>
		</div>
	);

	const Input = (name: keyof OnlyStringKeys<Data>, label: string) => (
		<FormInput
			type="text"
			label={lang?.page.fields[name] || label}
			className="w-full"
			name={name}
			value={data[name] || ''}
			onChange={(val) => mutateData({ [name]: val })}
			errorMessages={!!errors && !!errors[name] ? errors[name] : null}
		/>
	);

	const Select = (name: keyof OnlyStringKeys<Data>, label: string) => (
		<SelectInput
			className={'mb-4'}
			name={name}
			options={[{ label: lang?.page.fields[name] || label, value: '' }]}
			value={data[name] || ''}
			onChange={(val) => mutateData({ [name]: val })}
		/>
	);

	const viewable = (props: {
		themes?: typeof theme | (typeof theme)[];
		type?: 'distributor' | 'installer';
	}) => {
		const { themes, type } = props;

		if (themes && ![themes].flat().includes(theme)) {
			return false;
		}

		if (type && data.type !== type) {
			return false;
		}

		return true;
	};

	const toshibaInstaller = viewable({ themes: 'toshiba', type: 'installer' });
	const toshibaDistributor = viewable({
		themes: 'toshiba',
		type: 'distributor',
	});
	const vokeraViessmann = viewable({ themes: ['vokera', 'viessmann'] });

	useEffect(() => {
		read<{ data: Data }>()
			.then((res) => {
				if (!res || !res.data) return;
				mutateData(res.data.data);
			})
			.catch(console.error);

		getLangPageFromApi<Page<LangMyCompany>>('dashboard/my_company').then(
			(res) => setLang(res)
		);
	}, []);

	return (
		<PageLayout
			title={StringHelpers.title(lang?.page.title || 'My Company')}
		>
			<div className="row justify-content-center">
				<div className="col-12 col-md-10 col-lg-8 col-xl-6">
					{Input('name', 'Name')}
					<PostcodeLookup
						data={data}
						setData={mutateData}
						errors={errors}
					/>
					{toshibaInstaller &&
						Input(
							'fgas_refcom_standard_number',
							'FGAS / REFCOM standard number'
						)}
					{toshibaInstaller &&
						Input('refcom_elite_number', 'REFCOM elite number')}
					{toshibaInstaller &&
						Input('account_number', 'Account number')}
					{toshibaDistributor &&
						Input(
							'dispatch_note_field_label',
							'Dispatch note field label'
						)}
					{toshibaDistributor &&
						Checkbox(
							'dispatch_note_required',
							'Dispatch note required'
						)}
					{toshibaDistributor &&
						Select(
							'distributor_claim_type',
							'Distributor claim type'
						)}
					{toshibaDistributor &&
						Input(
							'distributor_claim_inbox_email',
							'Distributor claims inbox email'
						)}
					{vokeraViessmann &&
						Checkbox(
							'gas_boiler_systems_enabled',
							'Gas boilers systems'
						)}
					{vokeraViessmann &&
						Checkbox(
							'heat_pumps_systems_enabled',
							'Heat pumps systems'
						)}
					{vokeraViessmann &&
						Checkbox('solar_systems_enabled', 'Solar systems')}
					{vokeraViessmann &&
						Checkbox('other_systems_enabled', 'Other systems')}
					{vokeraViessmann &&
						Input('gas_safe_number', 'Gas Safe number')}
					{vokeraViessmann &&
						Checkbox(
							'gas_safe_confirmation',
							'Gas Safe confirmation'
						)}
					<Button
						label={
							isUpdating
								? lang?.page.submit_button.submitting ||
								  'submitting'
								: lang?.page.submit_button.default || 'Submit'
						}
						onClick={update}
					/>
				</div>
			</div>
		</PageLayout>
	);
}

export default MyCompany;
