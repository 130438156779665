import axios from 'axios';
import {
	API_ENDPOINTS,
	AppContainer,
	AuthPaneContainer,
	Button,
	customToast,
	FormInput,
	getLocalUrl,
	getThemeFromUrl,
	LangContext,
	LargeThemeLogo,
	route,
	Spinner,
	Title,
	Variants,
	PasswordFieldAndRules,
	StringHelpers, setCookie, COOKIE_TYPES
} from 'carrier-fe';
import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

function ResetPassword() {
	const [searchParams] 	= useSearchParams();
	const { getLangPage }	= useContext(LangContext);
	const navigate 			= useNavigate();

	const [lang, setLang] 				= useState<any>(null);
	const [submitting, setSubmitting]	= useState(false);
	const [data, setData] 				= useState({
		token:					searchParams.get('token') || '',
		email: 					searchParams.get('email') || '',
		password: 				'',
		password_confirmation:	'',
	});

	const [errors, setErrors] = useState<{
		password: 				string[];
		password_confirmation:	string[];
	} | null>(null);

	useEffect(() => {
		if (lang === null) {
			load();
		}
	}, []);

	useEffect(() => {
		if (submitting) {
			setErrors(null);
			submit();
		}
	}, [submitting]);

	const load = async () => {
		getLangPage('dashboard/reset_password')
			.then((res: any) => {
				if (typeof res === "undefined" || res === null) {
					setLang([]);
				} else {
					setLang(res);

					document.title = res?.page.title ?? 'Reset password';
				}
			})
			.catch((err: any) => console.error(err));
	};

	const submit = () => {
		axios
			.patch(route(API_ENDPOINTS.AUTH.RESET_PASSWORD), data)
			.then((res) => {
				customToast({
					title: res.data.message,
					variant: Variants.Success,
				});

				login();
			})
			.catch((error: any) => {
				if (!!error && !!error.response && !!error.response.data) {
					if (!!error.response.data.message) {

						customToast({
							title: error.response.data.message,
							variant: Variants.Danger,
						});
					}

					if (!!error.response.data.data && !!error.response.data.data.errors) {
						setErrors(error.response.data.data.errors);
					}
				} else {
					console.log(error);
					customToast({
						title: 'An error occurred. Please refresh the page to try again.',
						variant: Variants.Danger,
					});
				}

				setSubmitting(false)
			});
	};

	const login = () => {
		axios
			.post<Response>(route(API_ENDPOINTS.AUTH.LOGIN), {
				username: data.email,
				password: data.password,
			})
			.then((res: any) => {
				const token = res.data.data.token.token;

				if (token) {
					setCookie(COOKIE_TYPES.TOKEN, token);
				}

				customToast({
					title: res.data.message,
					variant: Variants.Success,
				});

				navigate('/dashboard');
			})
			.catch((error: any) => {
				if (!!error && !!error.response && !!error.response.data) {
					if (!!error.response.data.message) {

						customToast({
							title: error.response.data.message,
							variant: Variants.Danger,
						});
					}

					navigate('/login');
				} else {
					console.log(error);
					customToast({
						title: 'An error occurred. Please refresh the page to try again.',
						variant: Variants.Danger,
					});

					navigate('/login');
				}
			})
			.finally(() => setSubmitting(false));
	}

	const handleFieldUpdate = (field: string, value: any) => {
		let cloned: any = structuredClone(data);

		cloned[field] = value;

		setData(cloned);
	};

	const renderButtonTitle = () => {
		if (submitting) {
			return lang?.page.submit_button.submitting || 'Resetting...';
		}

		return lang?.page.submit_button.default || 'Reset';
	};

	// TODO - Create a branded loading spinner
	if (lang === null) {
		return (
			<div className="d-flex align-items-center vh-100 justify-content-center">
				<Spinner />
			</div>
		);
	}

	return (
		<AppContainer>
			<AuthPaneContainer fillerImage={getLocalUrl() + '/auth_cover.png'}>
				<LargeThemeLogo theme={getThemeFromUrl()} />
				<Title
					title={StringHelpers.title(lang?.page.title || 'Reset password')}
					className="mb-3 text-center w-100"
				/>
				<FormInput
					type="email"
					label={StringHelpers.title(lang?.fields.email || 'Email')}
					name="email"
					value={data.email}
					disabled={true}
					onChange={() => {}}
				/>
				<PasswordFieldAndRules
					password={{
						value:          data.password ?? '',
						label:          StringHelpers.title(lang?.fields.password || 'Password'),
						name:           'password',
						onChange:       (value: string) => handleFieldUpdate('password', value),
						errorMessages:  !!errors && !!errors.password ? errors.password : null,
					}}
					password_confirmation={{
						value:          data.password_confirmation ?? '',
						label:          StringHelpers.title(lang?.fields.password_confirmation || 'Confirm password'),
						name:           'password_confirmation',
						onChange:       (value: string) => handleFieldUpdate('password_confirmation', value),
						errorMessages:  !!errors && !!errors.password_confirmation ? errors.password_confirmation : null,
					}}
					lang={{
						declaration: lang?.page.password.declaration || 'For a strong password please satisfy the below password requirements.',
						rules: {
							min_char:	lang?.page.password.min_char || 'Minimum 8 characters',
							lower:		lang?.page.password.lower || 'Contains lowercase characters',
							upper:		lang?.page.password.upper || 'Contains uppercase characters',
							numeric:	lang?.page.password.numeric || 'Contains numeric characters',
							symbols:	lang?.page.password.symbols || 'Contains symbols characters',
							confirmed: 	lang?.page.password.confirmed || 'Passwords must match',
						}
					}}
				/>
				<div
					className="d-flex justify-content-end align-items-center mb-5"
					style={{ marginTop: '-1rem' }}
				>
					<Link
						className="text-decoration-none"
						to="/login"
					>
						{lang?.page.links.login || 'Back to login'}
					</Link>
				</div>
				<Button
					label={renderButtonTitle()}
					onClick={() => setSubmitting(true)}
					className="w-100 justify-content-center mb-3"
					variant={Variants.Info}
					disabled={submitting}
				/>
			</AuthPaneContainer>
		</AppContainer>
	);
}

export default ResetPassword;
