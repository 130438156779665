import {useEffect} from "react";
import {route, UrlBaseTypes} from "carrier-fe";

function Root() {
    /*
     * Redirect to the main login page
     */
    useEffect(() => {
        window.location.href = route('/login', undefined, UrlBaseTypes.DASHBOARD);
    }, []);

    return null;
}

export default Root;
