import axios from 'axios';
import {
	API_ENDPOINTS,
	Button,
	Card,
	CardBody,
	customToast,
	FormInput,
	route,
	StringHelpers,
	useDisabledContext,
	useLangContext,
	Variants,
} from 'carrier-fe';
import { useEffect, useState } from 'react';

export interface GasSafeEngineerLookupProps {
	data: any;
	setData: (value: any) => void;
	isChecked: boolean;
	setIsChecked: (value: boolean) => void;
}

function GasSafeEngineerLookup(props: GasSafeEngineerLookupProps) {
	const { data, setData, isChecked, setIsChecked } = props;
	const { disabled, setDisabled } = useDisabledContext();
	const [engineer, setEngineer] = useState<any>(null);
	const [errors, setErrors] = useState<any>(null);

	const { fields } = useLangContext();
	const [submitting, setSubmitting] = useState<boolean>(false);

	const handleCheckboxChange = () => {
		setIsChecked(!isChecked);
	};

	const handleFieldUpdate = (field: string, value: any) => {
		let cloned: any = structuredClone(data);

		cloned[field] = value;

		setData(cloned);
	};

	const getGasSafeEngineer = () => {
		axios
			.get(
				`${route(
					API_ENDPOINTS.GENERIC.GAS_SAFE.ENGINEER.VIEW
				)}?gas_safe_licence_card_number=${
					data.gas_safe_licence_card_number
				}`
			)
			.then((res) => setEngineer(res.data.data))
			.catch((error) => {
				if (!!error && !!error.response && !!error.response.data) {
					if (!!error.response.data.message) {
						customToast({
							title: error.response.data.message,
							variant: Variants.Danger,
						});
					}

					if (
						!!error.response.data.data &&
						!!error.response.data.data.errors
					) {
						setErrors(error.response.data.data.errors);
					}
				} else {
					console.log(error);
					customToast({
						title: 'An error occurred. Please refresh the page to try again.',
						variant: Variants.Danger,
					});
				}
			})
			.finally(() => {
				setSubmitting(false);
				setDisabled(false);
			});
	};

	useEffect(() => {
		if (submitting) {
			setDisabled(true);
			setErrors(null);
			getGasSafeEngineer();
		}
	}, [submitting]);

	return (
		<div className="mb-4">
			<div className="d-flex gap-3">
				<div className="flex-grow-1">
					<FormInput
						type="text"
						label={fields?.gas_safe_number || 'GasSafe number'}
						name="gas_safe_licence_card_number"
						value={data.gas_safe_licence_card_number || ''}
						onChange={(value) =>
							handleFieldUpdate(
								'gas_safe_licence_card_number',
								value
							)
						}
						loading={submitting}
						errorMessages={errors?.gas_safe_licence_card_number}
					/>
				</div>
				<Button
					label={StringHelpers.title(
						fields?.gas_safe_lookup || 'Search'
					)}
					variant={Variants.Dark}
					className="align-self-start py-3 px-4 mb-4"
					onClick={() => setSubmitting(true)}
					disabled={disabled}
				/>
			</div>

			{engineer && (
				<Card>
					<CardBody>
						<h5 className="mb-3">Details</h5>
						<div className="d-flex my-1 align-items-center justify-content-between">
							<div>First name</div>
							<div className="fw-bold">{engineer.first_name}</div>
						</div>
						<div className="d-flex my-1 align-items-center justify-content-between">
							<div>Last name</div>
							<div className="fw-bold">{engineer.last_name}</div>
						</div>
						<div className="d-flex my-1 align-items-center justify-content-between">
							<div>Gas safe licence card number</div>
							<div className="fw-bold">
								{engineer.gas_safe_licence_card_number}
							</div>
						</div>
						<div className="form-check mt-4">
							<input
								type="checkbox"
								id="gas_safe_confirmed"
								checked={isChecked}
								onChange={handleCheckboxChange}
								className="form-check-input"
							/>
							<label
								htmlFor="gas_safe_confirmed"
								className="form-check-label"
							>
								Gas safe confirmation
							</label>
						</div>
					</CardBody>
				</Card>
			)}
		</div>
	);
}

export default GasSafeEngineerLookup;
