import {
	PageLayout,
	Card,
	CardBody,
	Button,
	CardPlaceholder,
	Permissions,
	getRegistrationUrl,
	getAdminUrl,
	getTrainingUrl,
	Icon,
	Icons,
	useAuthorisationContext,
	useLangContext,
	StringHelpers,
} from 'carrier-fe';
import { useState, useEffect } from 'react';

function Dashboard() {
	const { hasPermissionAccess } = useAuthorisationContext();

	const { getLangPage } = useLangContext();
	const [lang, setLang] = useState<any>(null);

	useEffect(() => {
		if (lang === null) {
			load();
		}
	}, []);

	const load = async () => {
		getLangPage('dashboard/dashboard')
			.then((res: any) => {
				if (typeof res === 'undefined' || res === null) {
					setLang([]);
				} else {
					setLang(res);

					document.title = StringHelpers.title(
						res?.page.title ?? 'Dashboard'
					);
				}
			})
			.catch((err: any) => console.error(err));
	};

	const getTileById = (id: string) => {
		if (!lang || !lang.page || !lang.page.tiles) return null;

		for (let i = 0; i < lang.page.tiles.length; i++) {
			if (lang.page.tiles[i]['id'] === id) {
				return lang.page.tiles[i];
			}
		}

		return null;
	};

	const renderRegistrationTile = () => {
		if (
			!hasPermissionAccess(
				Permissions.REGISTRATION_AND_WARRANTY_PLATFORM_VIEW
			)
		)
			return null;

		let content = getTileById('registration.dashboard');

		return (
			<Card noMargin>
				<CardBody>
					<Icon
						icon={Icons.PROFILE}
						style={{ color: '#0d6efd' }}
					/>
					<h4 className="card-title">
						{!!content && !!content.title
							? content.title
							: 'Registrations'}
					</h4>
					<p className="card-text mb-4">
						{!!content && !!content.description
							? content.description
							: 'Access the registrations and warranty dashboard'}
					</p>
					<Button
						label={
							!!content && !!content.button
								? content.button
								: 'Go to'
						}
						href={getRegistrationUrl() + '/dashboard'}
						className="d-inline-flex"
					/>
				</CardBody>
			</Card>
		);
	};

	const renderTrainingTile = () => {
		if (!hasPermissionAccess(Permissions.TRAINING_PLATFORM_VIEW))
			return null;

		let content = getTileById('training.dashboard');

		return (
			<Card noMargin>
				<CardBody>
					<Icon
						icon={Icons.APARTMENT}
						style={{ color: '#0d6efd' }}
					/>
					<h4 className="card-title">
						{!!content && !!content.title
							? content.title
							: 'Training'}
					</h4>
					<p className="card-text mb-4">
						{!!content && !!content.description
							? content.description
							: 'Access the training dashboard'}
					</p>
					<Button
						label={
							!!content && !!content.button
								? content.button
								: 'Go to'
						}
						href={getTrainingUrl() + '/dashboard'}
						className="d-inline-flex"
					/>
				</CardBody>
			</Card>
		);
	};

	const renderAdminTile = () => {
		if (!hasPermissionAccess(Permissions.ADMIN_PLATFORM_VIEW)) return null;

		let content = getTileById('admin.dashboard');

		return (
			<Card noMargin>
				<CardBody>
					<Icon
						icon={Icons.DASHBOARD}
						style={{ color: '#0d6efd' }}
					/>
					<h4 className="card-title">
						{!!content && !!content.title ? content.title : 'Admin'}
					</h4>
					<p className="card-text mb-4">
						{!!content && !!content.description
							? content.description
							: 'Access the admin dashboard'}
					</p>
					<Button
						label={
							!!content && !!content.button
								? content.button
								: 'Go to'
						}
						href={getAdminUrl() + '/dashboard'}
						className="d-inline-flex"
					/>
				</CardBody>
			</Card>
		);
	};

	return (
		<PageLayout
			title={StringHelpers.title(lang?.page.title ?? 'Dashboard')}
		>
			<div className="dashboard--inner--wrapper d-grid gap-4">
				{lang === null ? (
					<>
						<CardPlaceholder />
						<CardPlaceholder />
						<CardPlaceholder />
					</>
				) : (
					<>
						{renderRegistrationTile()}
						{renderTrainingTile()}
						{renderAdminTile()}
					</>
				)}
			</div>
		</PageLayout>
	);
}

export default Dashboard;
